@import "~simplebar/dist/simplebar.css";
// @import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;


  .mat-mdc-snack-bar-container {
    &.error {
      --mdc-snackbar-container-color: #f44336;
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-supporting-text-color: #fff;
    }
  }

  .mat-mdc-snack-bar-container {
    &.success {
      --mdc-snackbar-container-color: #4caf50;
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-supporting-text-color: #fff;
    }
  }

  .mat-mdc-snack-bar-container {
    &.warning {
      --mdc-snackbar-container-color: #ffc107;
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-supporting-text-color: #070707;
    }
  }

  .mat-mdc-snack-bar-container {
    &.info {
      --mdc-snackbar-container-color: #2196f3;
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-supporting-text-color: #fff;
    }
  }




  

}

  
  




